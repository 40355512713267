<template>
  <Teleport
    v-if="showDialog"
    to="body"
  >
    <div
      ref="$dialog"
      :class="{
        'osk-dialog': true,
        'osk-dialog_with-mobile-menu': withMobileMenu,
        'osk-dialog_only-top-mobile-menu': onlyTopMobileMenu,
        [`osk-dialog_position-${position}`]: !!position,
      }"
      :style="zIndex ? `z-index: ${zIndex}` : undefined"
    >
      <Transition :name="dialogTransition">
        <div
          v-if="showContent"
          :class="{
            'osk-dialog__body': true,
            [`osk-dialog__body_position-${position}`]: !!position,
            [`osk-dialog__body_size-${size}`]: !!size,
            'osk-dialog__body_fullHeight': fullHeight,
            'osk-dialog__body_fullHeightNoScroll': fullHeightNoScroll,
            'osk-dialog__body_fullWidth': fullWidth,
            'osk-dialog__body_noPadding': noPadding,
            'osk-dialog__body_noShadow': noShadow,
            'osk-dialog__body_dark': dark,
            [contentClass]: !!contentClass
          }"
        >
          <slot />

          <div
            v-if="!hideClose"
            class="osk-dialog__close"
            @click="handleClose"
          >
            <UikitIcon
              :name="isDesktop ? 'Cross' : 'Close'"
              size="l"
              :color="dark ? 'white' : undefined"
            />
          </div>
        </div>
      </Transition>

      <Transition
        v-if="showContentComp"
        name="fade"
      >
        <div
          v-if="showContent && !hideBackdrop && showBackdrop"
          class="osk-dialog__backdrop"
          @click="handleClickOutside"
        >
          &nbsp;
        </div>
      </Transition>

      <template v-else>
        <div
          v-if="showContent && !hideBackdrop && showBackdrop"
          class="osk-dialog__backdrop"
          @click="handleClickOutside"
        >
          &nbsp;
        </div>
      </template>

      <slot name="dialogs" />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
type TDialogPosition = 'standard' | 'right';

const props = withDefaults(defineProps<{
  modelValue: boolean
  contentClass?: string
  fullHeight?: boolean
  fullHeightNoScroll?: boolean
  fullWidth?: boolean
  persistent?: boolean
  position?: TDialogPosition
  noPadding?: boolean
  hideClose?: boolean
  withMobileMenu?: boolean
  onlyTopMobileMenu?: boolean
  closeOthersOnMobile?: boolean
  noShadow?: boolean
  hideBackdrop?: boolean
  slideRight?: boolean
  zIndex?: number
  dark?: boolean
  size?: 'medium'
  background?: 'black'
}>(), {
  contentClass: '',
  position: 'standard',
  zIndex: undefined,
  size: 'medium',
  background: undefined,
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'keyup', value: KeyboardEvent): void,
  (e: 'handleClose'): void,
  (e: 'handleCloseOutside'): void,
}>();

let cancelBeforeEach: () => void | undefined;

const levelForPopstateClose = useState('levelForPopstateClose', () => 0);

const isPopstate = ref(false);
const currentLevel = ref(0);

const $dialog = ref();

const { isDesktop } = useUiBreakpoints();
const { preventBodyScroll, currentState } = usePreventScroll();
const router = useRouter();
const {
  shouldMobileBannerHide,
  dialogsLevel,
  currentDialog,
  closeOpenedDialog,
} = useDialog();

const showDialog = ref(false);
const showContent = ref(false);

const dialogTransition = computed(() => {
  if (props.slideRight) return 'slide-right';
  if (!isDesktop.value) return 'slide-down';

  return props.position === 'standard' ? 'fade' : 'slide-right';
});

const showBackdrop = computed(() => {
  if (dialogsLevel.value > 1) {
    return currentLevel.value === dialogsLevel.value;
  }

  return true;
});

const showContentComp = computed(() => {
  if (dialogsLevel.value === 0 && !props.modelValue && currentLevel.value === 1) {
    return true;
  }

  if (dialogsLevel.value === 0 && !props.modelValue && currentLevel.value !== 1) {
    return false;
  }

  return dialogsLevel.value === (props.modelValue ? 1 : 0);
});

function handleClose() {
  emits('update:modelValue', false);
  emits('handleClose');
}

function closeDialog() {
  showContent.value = false;

  setTimeout(() => {
    showDialog.value = false;
  }, 200);
}

function handleKeyUp(e:KeyboardEvent) {
  if (e.key !== 'Escape') return;

  emits('update:modelValue', false);
  emits('keyup', e);
}

function handleClickOutside() {
  if (props.persistent) return;

  emits('update:modelValue', false);
  emits('handleCloseOutside');
}

function handlePopstate() {
  isPopstate.value = true;

  if (!window.history.state?.back) {
    window.history.pushState({
      ...(window.history.state ?? {}),
      back: window.history.state.current,
    }, '');
  }

  if (levelForPopstateClose.value === currentLevel.value) {
    emits('update:modelValue', false);
  }
}

function addPopstateEvent() {
  if (!window.history.state?.back) {
    const state = {
      ...(window.history.state ?? {}),
      back: window.history.state.current,
    };

    // Нужно при первом открытии, если был открыт другой домен
    window.history.pushState(state, '');
    window.history.pushState(state, '');
  }

  cancelBeforeEach = router.beforeEach(async (to, from, next) => {
    // window popstate срабатывает позже beforeEach, поэтому магия с setTimeout
    // nextTick не работает
    await new Promise((res) => { setTimeout(res, 0); });

    if (isPopstate.value) {
      levelForPopstateClose.value = dialogsLevel.value;
      isPopstate.value = false;
      return next(false);
    }

    emits('update:modelValue', false);

    return next();
  });

  window.addEventListener('popstate', handlePopstate);
}

function handleMobileOpen() {
  if (props.closeOthersOnMobile) {
    shouldMobileBannerHide.value = true;
    closeOpenedDialog();

    currentDialog.value = {
      close: () => emits('update:modelValue', false),
    };
  }

  addPopstateEvent();
}

watch(() => props.modelValue, async (value) => {
  if (process.server) return;

  if (value) {
    if (!isDesktop.value) {
      handleMobileOpen();
    }

    dialogsLevel.value++;

    if (!currentState.value) {
      preventBodyScroll(true);
    }

    showDialog.value = true;
    await nextTick();
    showContent.value = true;

    currentLevel.value = dialogsLevel.value;

    if (isDesktop.value) {
      window.addEventListener('keyup', handleKeyUp);
    }
  } else {
    dialogsLevel.value--;
    await nextTick();

    levelForPopstateClose.value = 0;

    if (dialogsLevel.value <= 0) {
      dialogsLevel.value = 0;
      shouldMobileBannerHide.value = false;
      preventBodyScroll(false);
      currentDialog.value = null;
    }

    if (isDesktop.value) {
      window.removeEventListener('keyup', handleKeyUp);
    }

    closeDialog();

    if (cancelBeforeEach) {
      cancelBeforeEach();
    }
    window.removeEventListener('popstate', handlePopstate);
  }
});

onUnmounted(() => {
  if (cancelBeforeEach) {
    cancelBeforeEach();
  }

  window.removeEventListener('popstate', handlePopstate);
  window.removeEventListener('keyup', handleKeyUp);

  if (currentLevel.value) {
    currentLevel.value = 0;
    preventBodyScroll(false);
    levelForPopstateClose.value = 0;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;

  &:not(.osk-dialog_with-mobile-menu) {
    z-index: 1002;
  }

  .osk-dialog-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;
    padding: $indent-compact $indent-medium 42px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-query(lg-and-up) {
      padding: $indent-mlarge 52px 52px;
    }
  }

  .osk-dialog-title {
    @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
    padding: 10px 0;
  }

  &_position-standard {
    align-items: center;
    justify-content: center;
  }

  &_position-right {
    align-items: center;
  }

  &__backdrop {
    height: 100%;
    width: 100%;
    background-color: $black-op-6;
  }

  &__body {
    @include font-body-all;
    position: absolute;
    background-color: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: $radius-mlarge $radius-mlarge 0px 0px;
    bottom: 0;
    max-height: 100%;
    padding: 16px;
    z-index: 1;

    @include media-query(lg-and-up) {
      width: auto;
      bottom: auto;
      padding: 24px;
    }

    &_position-standard {
      @include media-query(lg-and-up) {
        border-radius: $radius-mlarge;
      }
    }

    &_position-right {
      @include media-query(md-and-up) {
        border-radius: $radius-mlarge 0 0 $radius-mlarge;
        right: 0;
      }
    }

    &_noPadding {
      padding: 0;
    }

    &_noShadow {
      box-shadow: none;
    }

    &_fullHeight {
      height: 100vh;
      top: 0;
      border-radius: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &_fullHeightNoScroll {
      overflow-y: visible;
    }

    &_fullWidth {
      width: 100vw;
    }

    &_dark {
      background: $primary;
      color: white;

      .osk-dialog__close {
        background: rgba(0,0,0,.2);
        border-radius: 100px;
        padding: 4px;
        right: 16px;
        top: 24px;
        z-index: 2;
        transition: all linear .2s;

        .osk-icon {
          width: 20px;
          height: 20px;
          display: block;
        }

        @include media-query(lg-and-up) {
          right: 50px;
          top: 44px;
        }
      }
    }

    &_size-medium {
      @include media-query(lg-and-up) {
        width: 504px;
      }
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 44px;
    right: 44px;

    @include media-query(lg-and-up) {
      top: 44px;
      right: 44px;
    }

    &:hover {
      opacity: .6;
    }

    &:active {
      opacity: .6;
    }
  }

  &_with-mobile-menu {
    bottom: 74px;
    top: 56px;

    &.osk-dialog_only-top-mobile-menu {
      bottom: 0;
      z-index: 1002;
    }
  }

  & ~ & {
    .osk-dialog__backdrop {
      width: calc(100% - 504px);
    }
  }
}
</style>
